import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { InitialState, IPlan } from '../../../redux/slice';
import { createAppAsyncThunk } from '../../../../hooks';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';
import { TLocation } from '../interface';

export type TWeightage = {
  readerShipIndex: number | string;
  competitionIndex: number | string;
  mashIndex: number | string;
  costIndex: number | string;
};

export const printHashUpdateWeightage = createAppAsyncThunk<
  any,
  { weightage: TWeightage; hashName: string; hashSpecificPath: string }
>(
  'plan-requests/printHashUpdateWeightage',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    if (
      planId &&
      data &&
      data?.weightage &&
      data?.hashName &&
      data?.hashSpecificPath
    ) {
      return API.put(`/plan-requests/${planId}/${data?.hashSpecificPath}`, {
        weightage: data?.weightage
      })
        .then((res: any) => {
          return {
            updatedPlan: res?.data?.updatedPlan,
            hashName: data?.hashName
          };
        })
        .catch((err: any) => {
          let response = { ...err.response };
          dispatch(
            SnackBarActions.openSnack({
              message: response?.data?.message || 'Error Occured.',
              open: true,
              type: 'error'
            })
          );
          throw err;
        });
    }
  }
);

export const printHashRankingFileUpload = createAppAsyncThunk<
  any,
  { file: File; hashName: string; hashSpecificPath: string }
>(
  'plan-requests/printHashRankingFileUpload',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    const formData = new FormData();
    formData.append('file', data.file);
    try {
      if (planId && data && data?.hashSpecificPath) {
        const response = await API.put(
          `/plan-requests/${planId}/${data?.hashSpecificPath}`,
          formData
        );
        dispatch(
          SnackBarActions.openSnack({
            message: 'Ranking File uploaded successfully',
            open: true,
            type: 'success'
          })
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occured.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const printHashUpdateRankingFileUpload = createAppAsyncThunk<
  any,
  { locations: TLocation; hashName: string; hashSpecificPath: string }
>(
  'plan-requests/printHashUpdateRankingFileUpload',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const { _id: planId, printHash } = state?.planRequests?.plan;

    try {
      if (
        planId &&
        printHash &&
        data &&
        data?.locations &&
        data?.hashSpecificPath
      ) {
        const response = await API.put(
          `/plan-requests/${planId}/${data.hashSpecificPath}`,
          {
            printHash: { ...printHash, locations: data.locations }
          }
        );
        dispatch(
          SnackBarActions.openSnack({
            message: 'Ranking File updated successfully',
            open: true,
            type: 'success'
          })
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occured.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const printHashMixMediaModeler = createAppAsyncThunk<
  any,
  { locations: TLocation; hashName: string; hashSpecificPath: string }
>(
  'plan-requests/printHashMixMediaModeler',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    try {
      if (planId && data && data?.locations && data?.hashSpecificPath) {
        const response = await API.put(
          `/plan-requests/${planId}/${data.hashSpecificPath}`,
          {
            ...data
          }
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occured.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const printHashRankingFileUploadExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder.addCase(printHashRankingFileUpload.fulfilled, (state, action) => {
    if (action?.payload?.updatedPlan && action?.payload?.hashName) {
      state.plan.currentState = action.payload.updatedPlan.currentState;
      state.plan[action.payload.hashName as keyof IPlan] =
        action.payload.updatedPlan[action.payload.hashName as string];
    }
  });
  builder.addCase(
    printHashUpdateRankingFileUpload.fulfilled,
    (state, action) => {
      if (action?.payload?.updatedPlan && action?.payload?.hashName) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan[action.payload.hashName as keyof IPlan] =
          action.payload.updatedPlan[action.payload.hashName as string];
      }
    }
  );
  builder.addCase(printHashMixMediaModeler.fulfilled, (state, action) => {
    if (action?.payload?.updatedPlan && action?.payload?.hashName) {
      state.plan.currentState = action.payload.updatedPlan.currentState;
      state.plan[action.payload.hashName as keyof IPlan] =
        action.payload.updatedPlan[action.payload.hashName as string];
    }
  });
  builder.addCase(printHashUpdateWeightage.fulfilled, (state, action) => {
    if (action?.payload?.updatedPlan && action?.payload?.hashName) {
      state.plan.currentState = action.payload.updatedPlan.currentState;
      state.plan[action.payload.hashName as keyof IPlan] =
        action.payload.updatedPlan[action.payload.hashName as string];
    }
  });
};
