import React, { useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import { StepIconProps } from '@mui/material/StepIcon';
import { Stack } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import StepperContent from './StepperContent';

import { useAppSelector } from '../../../hooks';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 14,
    left: 'calc(-50% + 10px)',
    right: 'calc(50% + 10px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#389169'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#389169'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
}));

interface IColorlibStepIcon extends StepIconProps {
  handleStep: (step: number) => void;
  index: number;
}

function ColorlibStepIcon(props: IColorlibStepIcon) {
  const { active, completed, handleStep, index } = props;

  return (
    <Button
      disabled={!active && !completed}
      onClick={() => handleStep(index)}
      sx={{
        minWidth: '32px',
        width: '32px',
        height: '32px',
        color: `${active ? '#EFF0F7' : completed ? '#389169' : '#F0F0F0'}`,
        backgroundColor: 'currentColor',
        borderRadius: '50%',
        '&:hover': { backgroundColor: 'currentColor' },
        '&:disabled': { color: '#EFF0F7' },
        '&:before': {
          content: "''",
          width: '20px',
          height: '20px',
          color: '#fff',
          backgroundColor: 'currentColor',
          borderRadius: '50%'
        },
        '&:after': {
          content: "''",
          width: '8px',
          height: '8px',
          color: `${active ? '#4E3BC9' : completed ? '#389169' : '#F0F0F0'}`,
          backgroundColor: 'currentColor',
          borderRadius: '50%',
          position: 'absolute'
        },
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0
      }}
    ></Button>
  );
}

const steps = [
  'Campaign Brief',
  'Target Group',
  'Audience Context File',
  'User Persona File',
  'Media Space',
  'Audience Selection',
  'Budget Allocator'
];

export const helperSteps = {
  'target-group': 1,
  'audience-context-file': 2,
  'user-persona-file': 3,
  'media-space': 4,
  'audience-selection': 5,
  'budget-allocator': 6
};

export const helperStepsNumKey: { [k: number]: string } = {
  1: 'target-group',
  2: 'audience-context-file',
  3: 'user-persona-file',
  4: 'media-space',
  5: 'audience-selection',
  6: 'budget-allocator'
};

const getPath = (step: number, planId: string) => {
  let path = `/plans/${planId}/digital-hash`;
  if (helperStepsNumKey[step as keyof typeof helperStepsNumKey]) {
    path += `/${helperStepsNumKey[step as keyof typeof helperStepsNumKey]}`;
  }
  return path;
};

export default function CustomStepper({
  children
}: {
  children: React.ReactNode;
}) {
  const plan = useAppSelector((state: any) => state.planRequests?.plan),
    { currentState = 0 } = plan,
    location = useLocation(),
    path = location.pathname,
    { planId } = useParams(),
    segments = path.split('/'),
    navigate = useNavigate();

  let stage = segments[segments.length - 1];

  const [activeStep, setActiveStep] = useState(() =>
      currentState ? currentState / 10 : 0
    ),
    [completed, setCompleted] = useState<{
      [k: number]: boolean;
    }>({});

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    if (planId) {
      let path = getPath(newActiveStep, planId);
      navigate(path);
    }
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((helperSteps[stage as keyof typeof helperSteps] ?? 0) - 1);
    if (planId) {
      let path = getPath(
        (helperSteps[stage as keyof typeof helperSteps] ?? 0) - 1,
        planId
      );
      navigate(path);
    }
  };

  const handleStep = (step: number) => {
    setActiveStep(step);
    if (planId) {
      let path = getPath(step, planId);
      navigate(path);
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const renderStepperContent =
    plan && plan?._campaign && plan?._campaign?.brief;

  return (
    <Stack sx={{ width: '100%', padding: '16px', gap: '16px' }}>
      <Typography variant="h1" sx={{ fontWeight: 500 }}>
        {steps[helperSteps[stage as keyof typeof helperSteps] ?? 0]}
      </Typography>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        sx={{ width: 'min(80%, calc(100% - 80px))' }}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            active={
              (helperSteps[stage as keyof typeof helperSteps] ?? 0) === index
            }
            completed={index <= (currentState ? currentState / 10 : 0)}
          >
            <StepLabel
              StepIconComponent={(p) => (
                <ColorlibStepIcon
                  {...p}
                  handleStep={handleStep}
                  index={index}
                />
              )}
              sx={{
                '& .MuiStepLabel-label': {
                  color:
                    (helperSteps[stage as keyof typeof helperSteps] ?? 0) ===
                    index
                      ? '#4e3bc9'
                      : '#172b4d',
                  fontWeight:
                    (helperSteps[stage as keyof typeof helperSteps] ?? 0) ===
                    index
                      ? 600
                      : 500
                }
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {renderStepperContent ? (
        <StepperContent
          activeStep={activeStep}
          allStepsCompleted={allStepsCompleted}
          handleReset={handleReset}
          handleBack={handleBack}
          completed={completed}
          handleComplete={handleComplete}
          steps={steps}
          stage={stage}
        >
          {children}
        </StepperContent>
      ) : null}
    </Stack>
  );
}