import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import StepperAction from './StepperAction';
import RightSideContent from './RightSideContent';

import { helperSteps } from './index';

import { useAppSelector } from '../../../hooks';
import { getDigitalHashData } from './defaultDigitalHashData';

import classes from './Stepper.module.css';

interface ILoaderProps {
  children: React.ReactNode;
  isLoading: boolean;
}

interface IStepperContent {
  children: React.ReactNode;
  activeStep: number;
  allStepsCompleted: () => boolean;
  handleReset: () => void;
  handleBack: () => void;
  completed: {
    [k: number]: boolean;
  };
  handleComplete: () => void;
  steps: string[];
  stage: string;
}

interface IDoWeHaveContentToShowProps {
  currentState: number;
  userName: string;
  children: React.ReactNode;
}

const Loader = ({ children, isLoading }: ILoaderProps) => {
  if (!isLoading) return <React.Fragment>{children}</React.Fragment>;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <CircularProgress />
    </Box>
  );
};

const DoWeHaveContentToShow = ({
  currentState,
  userName,
  children
}: IDoWeHaveContentToShowProps) => {
  const currentStateFromDb = currentState ? currentState / 10 : 0,
    location = useLocation(),
    path = location.pathname,
    segments = path.split('/'),
    stage = segments[segments.length - 1],
    validate =
      currentStateFromDb < helperSteps[stage as keyof typeof helperSteps];

  return validate ? (
    <Stack
      sx={{
        display: 'grid',
        placeContent: 'center',
        height: '100%'
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: '#4E3BC9',
          width: '75%',
          margin: 'auto',
          textAlign: 'center'
        }}
      >
        &#128544; Hello {userName}, <br /> You need to complete the previous
        steps to access this tab.
      </Typography>
    </Stack>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

const StepperContent = ({
  children,
  activeStep,
  handleBack,
  completed,
  handleComplete,
  steps,
  stage
}: IStepperContent) => {
  const plan = useAppSelector((state: any) => state.planRequests?.plan),
    userName = useAppSelector((state: any) => state?.session?.user?.name) ?? '',
    { currentState = 0 } = plan,
    [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      _campaign: {
        ...plan?._campaign
      },
      digitalHash: {
        ...getDigitalHashData({
          digitalHash: plan?.digitalHash ?? {},
          brief: plan?._campaign?.brief
        })
      }
    }
  });

  const handleLoading = (isLoading: boolean) => {
    setIsLoading(isLoading);
  };

  return (
    <FormProvider {...methods}>
      <Box className={classes.stepper_content_container}>
        <Box className={`${classes.section_container}`}>
          <Box className={`${classes.main_section} ${classes.scroll_bar}`}>
            <Loader isLoading={isLoading}>
              <DoWeHaveContentToShow
                currentState={currentState}
                userName={userName}
              >
                {children}
              </DoWeHaveContentToShow>
            </Loader>
            <StepperAction
              activeStep={activeStep}
              completed={completed}
              handleBack={handleBack}
              handleComplete={handleComplete}
              steps={steps}
              isLoading={isLoading}
              handleLoading={handleLoading}
            />
          </Box>
          {(helperSteps[stage as keyof typeof helperSteps] ?? 0) < 4 ? (
            <Box className={`${classes.aside_section} ${classes.scroll_bar}`}>
              <Loader isLoading={isLoading}>
                <DoWeHaveContentToShow
                  currentState={currentState}
                  userName={userName}
                >
                  <RightSideContent
                    activeStep={
                      helperSteps[stage as keyof typeof helperSteps] ?? 0
                    }
                  />
                </DoWeHaveContentToShow>
              </Loader>
            </Box>
          ) : null}
        </Box>
      </Box>
    </FormProvider>
  );
};

export default StepperContent;
