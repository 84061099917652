import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IPlan, InitialState } from '../../../redux/slice';
import { createAppAsyncThunk } from '../../../../hooks';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';

interface IOutdoorHashInventoryFileUpload {
  location: string;
  vendor: {
    _id: string;
    contact_name: string;
    company_name: string;
  };
  file: File;
  hashName: string;
}

interface IOutdoorHashInventoryDelete {
  inventoryIds: string[];
  hashName: string;
}

export const outdoorHashInventoryFileUpload = createAppAsyncThunk<
  any,
  IOutdoorHashInventoryFileUpload
>(
  'plan-requests/outdoorHashInventoryFileUpload',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    let formData = new FormData();
    try {
      if (
        planId &&
        data?.file &&
        data?.vendor &&
        data?.location &&
        data?.hashName
      ) {
        formData.append('location', data.location);
        formData.append('vendor', JSON.stringify(data.vendor));
        formData.append('file', data.file);
        const response = await API.post(
          `/plan-requests/${planId}/hash/outdoor/inventory`,
          formData
        );
        dispatch(
          SnackBarActions.openSnack({
            message: `${
              data.vendor?.contact_name || ''
            } file uploaded successfully`,
            open: true,
            type: 'success'
          })
        );
        return {
          updatedPlan: response.data?.updatedPlanRequest,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occured.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const outdoorHashInventoryDelete = createAppAsyncThunk<
  any,
  IOutdoorHashInventoryDelete
>(
  'plan-requests/outdoorHashInventoryDelete',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (planId && data?.inventoryIds) {
        const response = await API.delete(
          `/plan-requests/${planId}/hash/outdoor/inventory?ids=${JSON.stringify(
            data.inventoryIds
          )}`
        );
        dispatch(
          SnackBarActions.openSnack({
            message: `Selected Inventory has been deleted successfully`,
            open: true,
            type: 'success'
          })
        );

        return {
          updatedPlan: response.data?.updatedPlanRequest,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occured.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const outdoorHashMediaMapping = createAppAsyncThunk<
  any,
  { hashName: string }
>(
  'plan-requests/outdoorHashMediaMapping',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (planId && data.hashName) {
        const response = await API.put(
          `/plan-requests/${planId}/hash/outdoor/media-mapping`
        );
        dispatch(
          SnackBarActions.openSnack({
            message: `${
              response.data?.message
                ? response.data.message
                : 'Tasker started successfully'
            }`,
            open: true,
            type: 'success'
          })
        );

        return {
          updatedPlan: response.data?.updatedPlanRequest,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occured.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const outdoorHashVendorExcelUploadExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder
    .addCase(outdoorHashInventoryFileUpload.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan && action?.payload?.hashName) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan[action.payload.hashName as keyof IPlan] =
          action.payload.updatedPlan[action.payload.hashName];
      }
    })
    .addCase(outdoorHashInventoryDelete.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan && action?.payload?.hashName) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan[action.payload.hashName as keyof IPlan] =
          action.payload.updatedPlan[action.payload.hashName];
      }
    })
    .addCase(outdoorHashMediaMapping.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan && action?.payload?.hashName) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan[action.payload.hashName as keyof IPlan] =
          action.payload.updatedPlan[action.payload.hashName];
      }
    });
};
