import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IPlan, InitialState } from '../../../redux/slice';
import { createAppAsyncThunk } from '../../../../hooks';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';
import { IVendorInventory } from '../interface';

interface IOutdoorHashBulkAssignTags {
  tags: string[];
  inventoryIds: string[];
  hashName: string;
}

interface IOutdoorHashSelectMedia {
  mediaId: string;
  inventoryId: string;
  hashName: string;
}

interface IOutdoorHashCreateMedia {
  inventory: IVendorInventory;
  hashName: string;
}

export const outdoorHashUploadMediaLogo = createAppAsyncThunk<
  any,
  { file: File }
>(
  'plan-requests/outdoorHashUploadMediaLogo',
  async (data, { extra: { API }, dispatch }) => {
    const formData = new FormData();
    formData.append('file', data.file);
    try {
      if (data.file) {
        const response = await API.post(`media/upload/logo`, formData);
        dispatch(
          SnackBarActions.openSnack({
            message: 'Image uploaded successfully',
            open: true,
            type: 'success'
          })
        );
        return response?.data?.imageUrl;
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occured.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const outdoorHashBulkAssignTags = createAppAsyncThunk<
  any,
  IOutdoorHashBulkAssignTags
>(
  'plan-requests/outdoorHashBulkAssignTags',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (data?.tags && data?.inventoryIds && data?.hashName) {
        const response = await API.put(
          `plan-requests/${planId}/hash/outdoor/inventory/tags`,
          {
            tags: data.tags,
            inventoryIds: data.inventoryIds
          }
        );
        dispatch(
          SnackBarActions.openSnack({
            message: 'Tags updated succesfully',
            open: true,
            type: 'success'
          })
        );
        return {
          updatedPlan: response.data?.updatedPlanRequest,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occured.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const outdoorHashSelectMedia = createAppAsyncThunk<
  any,
  IOutdoorHashSelectMedia
>(
  'plan-requests/outdoorHashSelectMedia',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (planId && data?.inventoryId && data?.mediaId && data?.hashName) {
        const response = await API.put(
          `plan-requests/${planId}/hash/outdoor/inventory/${data.inventoryId}/medias/${data.mediaId}`
        );
        dispatch(
          SnackBarActions.openSnack({
            message: 'The chosen media has been updated.',
            open: true,
            type: 'success'
          })
        );
        return {
          updatedPlan: response.data?.updatedPlanRequest,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occured.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const outdoorHashCreateMedia = createAppAsyncThunk<
  any,
  IOutdoorHashCreateMedia
>(
  'plan-requests/outdoorHashCreateMedia',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (planId && data?.inventory && data?.hashName) {
        const response = await API.post(
          `plan-requests/${planId}/hash/outdoor/create-media`,
          {
            inventory: data.inventory
          }
        );
        dispatch(
          SnackBarActions.openSnack({
            message: 'The media has been created.',
            open: true,
            type: 'success'
          })
        );
        return {
          updatedPlan: response.data?.updatedPlanRequest,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occured.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const outdoorHashListingAndTaggingExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder
    .addCase(outdoorHashBulkAssignTags.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan && action?.payload?.hashName) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan[action.payload.hashName as keyof IPlan] =
          action.payload.updatedPlan[action.payload.hashName];
      }
    })
    .addCase(outdoorHashSelectMedia.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan && action?.payload?.hashName) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan[action.payload.hashName as keyof IPlan] =
          action.payload.updatedPlan[action.payload.hashName];
      }
    })
    .addCase(outdoorHashCreateMedia.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan && action?.payload?.hashName) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan[action.payload.hashName as keyof IPlan] =
          action.payload.updatedPlan[action.payload.hashName];
      }
    });
};
