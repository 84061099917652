import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from '../../hooks';
import { AxiosRequestConfig } from 'axios';
import { SnackBarActions } from '../../Shared/SnackBar/slice';
import { THash, TLocation, TWeightage } from '../ProjectHash/interface';
import { budgetAllocatorReducers } from '../ProjectHash/BudgetAllocator/redux/reducers';
import { campaignDurationReducers } from '../ProjectHash/CampaignDuration/redux/reducers';
import { campaignCalendarReducers } from '../ProjectHash/CampaignCalendar/redux/reducers';
import { mixMediaModelerReducers } from '../ProjectHash/MixMediaModeler/redux/reducers';
import { rankingFileUploadTable } from '../ProjectHash/RankingFileUpload/redux/reducers';
import {
  IBriefForm,
  IMediaPlanForm
} from '../../../components/FeedbackDialog/interface';
import { radioHashRankingFileUploadExtraReducers } from '../RadioHash/RankingFileUpload/reducers';
import {
  updateMediaSpecificDetailsRadioExtraReducers,
  updateMediaSpecificDetailsRadioReducers
} from '../RadioHash/RadioBrief/redux/reducers';
import { radioHashMediaSpaceSelectedStatonsExtraReducers } from '../RadioHash/MediaSpace/redux/reducers';
import { radioHashCampaignDurationExtraReducers } from '../RadioHash/CampaignDuration/redux/reducers';
import { radioHashCampaignCalendarExtraReducers } from '../RadioHash/CampaignCalendar/redux/reducers';
import { radioHashBudgetAllocatorExtraReducers } from '../RadioHash/BudgetAllocator/redux/reducers';
import { updateMediaSpecificDetailsPrintExtraReducers } from '../PrintHash/PrintBrief/redux/reducers';
import { printHashMediaSpaceSelectedStatonsExtraReducers } from '../PrintHash/MediaSpace/redux/reducers';
import { printHashRankingFileUploadExtraReducers } from '../PrintHash/RankingFileUpload/redux/reducers';
import { printHashCampaignDurationExtraReducers } from '../PrintHash/CampaignDuration/redux/reducers';
import { printHashCampaignCalendarExtraReducers } from '../PrintHash/CampaignCalendar/redux/reducers';
import { outdoorHashVendorCollectionExtraReducers } from '../OutdoorHash/VendorCollection/redux/reducers';
import { IGeo, IVendor } from '../OutdoorHash/VendorCollection/interface';
import { outdoorHashVendorExcelUploadExtraReducers } from '../OutdoorHash/VendorExcelUpload/redux/reducers';
import { outdoorHashListingAndTaggingExtraReducers } from '../OutdoorHash/ListingAndTagging/redux/reducers';
import { outdoorHashMappingExtraReducers } from '../OutdoorHash/Mapping/redux/reducers';
import { outdoorHashBudgetAllocatorExtraReducers } from '../OutdoorHash/BudgetAllocator/redux/reducers';
import { outdoorHashCampaignCalendarExtraReducers } from '../OutdoorHash/CampaignCalendar/redux/reducers';
import { digitalHashExtraReducers } from '../DigitalHash/Stepper/redux/reducers';

export interface IPlan {
  _campaign: any;
  currentState?: number;
  hash?: THash;
  radioHash?: any;
  printHash?: any;
  outdoorHash?: any;
  mediaSpaceAddCount?: number;
}

export interface InitialState {
  planRequests: any;
  count: number;
  isLoading: boolean;
  planLevelIsLoading: boolean;
  plan: IPlan;
  isSubmittingPlanFeedback: boolean;
  isSubmittingBriefFeedback: boolean;
  outdoorHashVendorCollection?: { geo: IGeo; availableVendors: IVendor[] }[];
  mediaOptionsTemplateIds: {
    _id: string;
    type: string;
    name: string;
    toolName: string;
    isActive: number;
  }[];
  languages: { _id: string }[];
}

const initialState: InitialState = {
  planRequests: [],
  count: 0,
  isLoading: false,
  planLevelIsLoading: true,
  plan: {
    _campaign: null
    // hash: hash
  },
  outdoorHashVendorCollection: [],
  isSubmittingBriefFeedback: false,
  isSubmittingPlanFeedback: false,
  mediaOptionsTemplateIds: [],
  languages: []
};

type TFetchPlans = { planRequests: any };

export const fetchPlans = createAppAsyncThunk<TFetchPlans, object>(
  'plan-requests/fetch',
  async (params: any, { extra: { API, SLS } }) => {
    return API.get(`/plan-requests?params=${JSON.stringify(params)}`).then(
      (res: any) => {
        return res.data;
      }
    );
  }
);

export const getPlanRequestsCount = createAppAsyncThunk<any, object>(
  'plan-requests/getPlanRequestsCount',
  async (params, { extra: { API }, dispatch }) => {
    try {
      const res = await API.get(
        `/plan-requests/count?params=${JSON.stringify(params)}`
      );
      return res.data;
    } catch (error: any) {
      console.log({ error });
      dispatch(
        SnackBarActions.openSnack({
          message: 'Error Occurred while fetching the count',
          open: true,
          type: 'error'
        })
      );
      throw error;
    }
  }
);

export const fetchPlan = createAppAsyncThunk<any, string>(
  'plan-requests/fetchPlan',
  async (planId, { getState, extra: { API, SLS } }) => {
    return API.get(`/plan-requests/${planId}`)
      .then((res: any) => res.data)
      .catch((e) => {
        console.log(e);
        throw e;
      });
  }
);

export const addPlanAgent = createAppAsyncThunk<
  any,
  { planId: string; agent: any; campaignId: string }
>('plan-requests/addAgent', async (data, { extra: { API, SLS }, dispatch }) => {
  return API.put(
    `/plan-requests/campaigns/${data.campaignId}/${data.planId}/agent/${data.agent._id}`
  )
    .then((res: any) => res.data)
    .catch((e: any) => {
      console.log(e);
      dispatch(
        SnackBarActions.openSnack({
          message: e?.response?.data?.error || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw e;
    });
});

type TUploadPlan = {
  id: string;
  campaignId: string;
  plan: any; // replace with the plan type later
  files: Blob[];
  user: {
    _id: string;
    name: string;
    email: string;
  };
};

export const uploadPlan = createAppAsyncThunk<any, TUploadPlan>(
  'plan-requests/upload-file',
  async (data, { extra: { API }, dispatch }) => {
    const formData = new FormData();
    if (data.files && data.files.length > 0) {
      data.files.forEach((file) => {
        formData.append('files', file);
      });
    }
    formData.append('plan', JSON.stringify(data.plan));

    return API.post(
      `/plan-requests/${data.id}/${data.campaignId}/plans`,
      formData as AxiosRequestConfig<TUploadPlan>,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then((res: any) => {
        dispatch(
          SnackBarActions.openSnack({
            message: 'Plan Uploaded Successfully',
            open: true,
            type: 'success'
          })
        );
        return { ...res.data, user: data.user };
      })
      .catch((e: any) => {
        console.log({ e });
        dispatch(
          SnackBarActions.openSnack({
            message: e?.response?.data?.error || 'Error in Uploading Plan',
            open: true,
            type: 'error'
          })
        );
        throw e;
      });
  }
);

export const updateTags = createAppAsyncThunk<
  any,
  { tags: string[]; id: string }
>('plan-requests/update-tags', async (data, { extra: { API }, dispatch }) => {
  try {
    const response = await API.put(`/plan-requests/${data.id}/tags`, {
      tags: data.tags
    });
    dispatch(
      SnackBarActions.openSnack({
        message: response.data,
        open: true,
        type: 'success'
      })
    );
    return response.data;
  } catch (err: any) {
    let response = { ...err.response };
    dispatch(
      SnackBarActions.openSnack({
        message: err?.response?.data?.error || 'Error Occurred.',
        open: true,
        type: 'error'
      })
    );
    throw err;
  }
});
export const updateDueDate = createAppAsyncThunk<
  any,
  { date: Date; id: string }
>(
  'plan-requests/update-dueDate',
  async (data, { extra: { API }, dispatch }) => {
    try {
      const response = await API.put(`/plan-requests/${data.id}/due-date`, {
        dueDate: data.date
      });
      dispatch(
        SnackBarActions.openSnack({
          message: response.data,
          open: true,
          type: 'success'
        })
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      console.log(response);
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data?.error || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

type TBulkAssignPlanRequestAgent = {
  agent: any;
  requestIds: string[];
};

export const assignBulkPlanRequestAgent = createAppAsyncThunk<
  any,
  TBulkAssignPlanRequestAgent
>(
  'plan-requests/assign-bulk-agent',
  async (data, { extra: { API }, dispatch }) => {
    return API.put(`/plan-requests/bulk-agent-assign/${data.agent._id}`, {
      requestIds: data.requestIds
    })
      .then((res: any) => {
        dispatch(
          SnackBarActions.openSnack({
            message: `${data?.agent?.name} Assigned successfully to selected Plan Requests`,
            open: true,
            type: 'success'
          })
        );
        return data;
      })
      .catch((e: any) => {
        console.log({ e });
        dispatch(
          SnackBarActions.openSnack({
            message: 'Error in assigning agent',
            open: true,
            type: 'error'
          })
        );
        throw e;
      });
  }
);

export const submitBrief = createAppAsyncThunk<
  any,
  { campaignId: string; brief: {} }
>('plan-requests/brief', async (data, { extra: { API, SLS }, dispatch }) => {
  const { campaignId, brief } = data;
  return SLS.put(`/campaigns/${campaignId}/brief`, JSON.stringify(brief), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res: any) => {
      return res.data.brief;
    })
    .catch((e: any) => {
      console.log({ e });
      dispatch(
        SnackBarActions.openSnack({
          message: e?.response?.data?.message || 'Error in submitting brief',
          open: true,
          type: 'error'
        })
      );
      throw e;
    });
});

export const addToMediaSpaceHash = createAppAsyncThunk<any, void>(
  'plan-requests/addToMediaSpaceHash',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    return SLS.put(`/campaigns/plan-request/${planId}`, JSON.stringify({}), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res: any) => {
        dispatch(
          SnackBarActions.openSnack({
            message: 'Successfully added to media space',
            open: true,
            type: 'success'
          })
        );
        return res.data;
      })
      .catch((e: any) => {
        console.log({ e });
        dispatch(
          SnackBarActions.openSnack({
            message:
              e?.response?.message || 'Error Occurred in adding the media space',
            open: true,
            type: 'error'
          })
        );
        throw e;
      });
  }
);

export const competitorFileUpload = createAppAsyncThunk<any, File>(
  'plan-requests/competitorFileUpload',
  async (file, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await API.put(
        `/plan-requests/${planId}/upload-competitor-file`,
        formData
      );
      dispatch(
        SnackBarActions.openSnack({
          message: 'Competitor data updated successfully',
          open: true,
          type: 'success'
        })
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateCurrentState = createAppAsyncThunk<any, { state: string }>(
  'plan-requests/updateCurrentState',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    try {
      const response = await API.put(
        `/plan-requests/${planId}/update-state?state=${data.state}`
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data?.message || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateState = createAppAsyncThunk<
  any,
  { state: number; hashName: string }
>(
  'plan-requests/updateState',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    try {
      if (planId && typeof data?.state === 'number' && data?.hashName) {
        const response = await API.put(`/plan-requests/${planId}/state`, {
          state: data.state
        });

        return {
          updatedPlan:
            response.data?.updatedPlanRequest ?? response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data?.message || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateWeightage = createAppAsyncThunk<
  any,
  { weightage: TWeightage }
>(
  'plan-requests/updateWeightage',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    if (planId) {
      return API.put(`/plan-requests/${planId}/weightage`, data)
        .then((res: any) => {
          return res?.data;
        })
        .catch((err: any) => {
          let response = { ...err.response };
          dispatch(
            SnackBarActions.openSnack({
              message: response?.data?.message || 'Error Occurred.',
              open: true,
              type: 'error'
            })
          );
          throw err;
        });
    }
  }
);

export const downloadTemplate = createAppAsyncThunk<any, void>(
  'plan-requests/downloadTemplate',
  async (_, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    if (planId) {
      return API.get(`/plan-requests/${planId}/download-template`)
        .then((res: any) => {
          if (res?.data?.url) {
            window.open(res.data.url);
          }
        })
        .catch((err: any) => {
          let response = { ...err.response };
          dispatch(
            SnackBarActions.openSnack({
              message: response?.data?.message || 'Error Occurred.',
              open: true,
              type: 'error'
            })
          );
          throw err;
        });
    }
  }
);

export const downloadPpt = createAppAsyncThunk<any, void>(
  'plan-requests/downloadPpt',
  async (_, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    if (planId) {
      return API.get(`/plan-requests/${planId}/ppt`)
        .then((res: any) => {
          if (res?.data?.url) {
            window.open(res.data.url);
          }
        })
        .catch((err: any) => {
          let response = { ...err.response };
          dispatch(
            SnackBarActions.openSnack({
              message: response?.data?.message || 'Error Occurred.',
              open: true,
              type: 'error'
            })
          );
          throw err;
        });
    }
  }
);

export const uploadRankingFile = createAppAsyncThunk<any, File>(
  'plan-requests/uploadRankingFile',
  async (file, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await API.put(
        `/plan-requests/${planId}/upload-ranking-file`,
        formData
      );
      dispatch(
        SnackBarActions.openSnack({
          message: 'Ranking File uploaded successfully',
          open: true,
          type: 'success'
        })
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateRankingFileUpload = createAppAsyncThunk<any, void>(
  'plan-requests/updateRankingFileUpload',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    const hash = state?.planRequests?.plan?.hash;
    try {
      const response = await API.put(
        `/plan-requests/${planId}/update-ranking-data`,
        {
          hash
        }
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data?.message || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const getTgBankData = createAppAsyncThunk<any, void>(
  'plan-requests/getTgBankData',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      const response = await API.put(`/plan-requests/${planId}/tg-bank`);
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data?.message || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const calculateMediaMixModeler = createAppAsyncThunk<any, void>(
  'plan-requests/calculateMediaMixModeler',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    const locations = state?.planRequests?.plan?.hash?.locations;
    try {
      const response = await API.put(
        `/plan-requests/${planId}/media-mix-modeler`,
        {
          locations
        }
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data?.message || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateMediaMixModeler = createAppAsyncThunk<any, void>(
  'plan-requests/updateMediaMixModeler',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    const locations = state?.planRequests?.plan?.hash?.locations;
    console.log(locations, 'this is location from media mix modeler.');
    try {
      const response = await API.put(
        `/plan-requests/${planId}/media-mix-modeler`,
        {
          locations
        }
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data?.message || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

type TCampaignDurationAddMonth = {
  locations: { geoHash: string; value: number }[];
};

export const campaignDurationAddMonth = createAppAsyncThunk<
  any,
  TCampaignDurationAddMonth
>(
  'plan-requests/campaignDurationAddMonth',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      const response = await API.put(`/plan-requests/${planId}/add-months`, {
        duration: data.locations
      });
      dispatch(
        SnackBarActions.openSnack({
          message: 'Number of Months updated successfully',
          open: true,
          type: 'success'
        })
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data?.message || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateCampaignDuration = createAppAsyncThunk<
  any,
  { locations: TLocation[] }
>(
  'plan-requests/updateCampaignDuration',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      const response = await API.put(
        `/plan-requests/${planId}/campaign-duration`,
        {
          locations: data.locations
        }
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data?.message || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateCampaignCalendar = createAppAsyncThunk<any, void>(
  'plan-requests/updateCampaignCalendar',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    const locations = state?.planRequests?.plan?.hash?.locations;
    try {
      const response = await API.put(
        `/plan-requests/${planId}/campaign-calendar`,
        {
          locations
        }
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data?.message || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateBudgetAllocator = createAppAsyncThunk<any, void>(
  'plan-requests/updateBudgetAllocator',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    const hash = state?.planRequests?.plan?.hash;
    try {
      const response = await API.put(
        `/plan-requests/${planId}/update-budget-allocator`,
        {
          hash
        }
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data?.message || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);
type TBriefFeedback = {
  planId: string;
  campaignId: string;
  feedback: IBriefForm;
};

export const briefFeedback = createAppAsyncThunk<any, TBriefFeedback>(
  'plan-requests/brief/feedback',
  async (data, { extra: { API }, dispatch }) => {
    return API.put(
      `/plan-requests/${data.planId}/${data.campaignId}/brief-feedback`,
      {
        feedback: data.feedback
      }
    )
      .then((res: any) => {
        dispatch(
          SnackBarActions.openSnack({
            message: 'Brief Feedback Submitted',
            open: true,
            type: 'success'
          })
        );
        return data;
      })
      .catch((e: any) => {
        console.log({ e });
        dispatch(
          SnackBarActions.openSnack({
            message: e?.response?.data?.error || 'Error in submitting feedback',
            open: true,
            type: 'error'
          })
        );
        throw e;
      });
  }
);

type TDescriptionFeedback = {
  planId: string;
  campaignId: string;
  rating: number;
};

export const descriptionFeedback = createAppAsyncThunk<
  any,
  TDescriptionFeedback
>(
  'plan-requests/description/feedback',
  async (data, { extra: { API }, dispatch }) => {
    try {
      const res = await API.put(
        `/plan-requests/${data.planId}/${data.campaignId}/description-feedback`,
        {
          rating: data.rating
        }
      );
      dispatch(
        SnackBarActions.openSnack({
          message: 'Description rated successfully',
          open: true,
          type: 'success'
        })
      );
      return data.rating;
    } catch (error: any) {
      console.log({ error });
      dispatch(
        SnackBarActions.openSnack({
          message:
            error?.response?.data?.error || 'Error in submitting feedback',
          open: true,
          type: 'error'
        })
      );
      throw error;
    }
  }
);

type TUpdateStatus = {
  requestId: string;
  status: string;
  cancellationReason: string;
  cancellationComment: string;
};

export const updateStatus = createAppAsyncThunk<any, TUpdateStatus>(
  'plan-requests/status/update',
  async (data, { extra: { API }, dispatch }) => {
    try {
      await API.patch(
        `/plan-requests/${data.requestId}/status/${data.status}`,
        {
          cancellationReason: data.cancellationReason,
          cancellationComment: data.cancellationComment
        }
      );
      dispatch(
        SnackBarActions.openSnack({
          message: 'Status updated successfully',
          open: true,
          type: 'success'
        })
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        SnackBarActions.openSnack({
          message: error.response.data.error || 'Error in updating status',
          open: true,
          type: 'error'
        })
      );
      throw error;
    }
  }
);

type TPlanFeedback = {
  requestId: string;
  planId: string;
  campaignId: string;
  feedback: IMediaPlanForm;
};

export const mediaPlanFeedback = createAppAsyncThunk<any, TPlanFeedback>(
  'plan-requests/plan/feedback',
  async (data, { extra: { API }, dispatch }) => {
    return API.put(
      `/plan-requests/${data.requestId}/plans/${data.planId}/${data.campaignId}/plan-feedback`,
      { feedback: data.feedback }
    )
      .then((res: any) => {
        dispatch(
          SnackBarActions.openSnack({
            message: 'Plan Feedback Submitted',
            open: true,
            type: 'success'
          })
        );
        return data;
      })
      .catch((e: any) => {
        console.log({ e });
        dispatch(
          SnackBarActions.openSnack({
            message: 'Error in submitting feedback',
            open: true,
            type: 'error'
          })
        );
        throw e;
      });
  }
);

type TGetMediaOptionTemplateId = {
  filters: {
    limit: number;
    skip: number;
    name: string;
    toolName: string;
  };
  projection: string[];
};

export const getMediaOptionTemplateId = createAppAsyncThunk<
  any,
  TGetMediaOptionTemplateId
>(
  'plan-requests/getMediaOptionTemplateId',
  async (data, { extra: { API, SLS }, dispatch }) => {
    try {
      const queryParams = encodeURIComponent(JSON.stringify(data));
      const promises = [
        API.get(`mediaOptionTemplate?params=${queryParams}`),
        API.get(`/${data?.filters?.toolName}/get-distinct-values`)
      ];

      const [responseMediaOptionTemplate, responseLanguages] =
        await Promise.all(promises);
      return {
        mediaOptionTemplate: responseMediaOptionTemplate?.data?.result,
        languages: responseLanguages?.data?.values
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const genericDownloadTemplate = createAppAsyncThunk<any, string>(
  'plan-requests/genericDownloadTemplate',
  async (path, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    if (planId) {
      return API.get(`/plan-requests/${planId}/${path}`)
        .then((res: any) => {
          if (res?.data?.url) {
            window.open(res.data.url);
          }
        })
        .catch((err: any) => {
          let response = { ...err.response };
          dispatch(
            SnackBarActions.openSnack({
              message:
                response?.data?.message || typeof response?.data === 'string'
                  ? response.data
                  : 'Error Occurred',
              open: true,
              type: 'error'
            })
          );
          throw err;
        });
    }
  }
);

export const summaryDownload = createAppAsyncThunk<
  any,
  { hashSpecificPath: string }
>(
  'plan-requests/summaryDownload',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    if (planId && data?.hashSpecificPath) {
      return API.get(`/plan-requests/${planId}/${data.hashSpecificPath}`)
        .then((res: any) => {
          if (res?.data?.url) {
            window.open(res.data.url);
          }
        })
        .catch((err: any) => {
          let response = { ...err.response };
          dispatch(
            SnackBarActions.openSnack({
              message: response?.data?.message || 'Error Occurred.',
              open: true,
              type: 'error'
            })
          );
          throw err;
        });
    }
  }
);

export const stpHistory = createAppAsyncThunk<any, string>(
  'plan-request/history',
  async (planRequestId, { extra: { API, SLS }, dispatch }) => {
    return API.get(`/plan-requests/${planRequestId}/activities`)
      .then((res: any) => {
        return res?.data;
      })
      .catch((e: any) => {
        console.log({ e });
        dispatch(
          SnackBarActions.openSnack({
            message:
              e?.response?.data?.message ||
              'Error Occurred in fetching STP history',
            open: true,
            type: 'error'
          })
        );
        throw e;
      });
  }
);

const planRequests = createSlice({
  name: 'planRequests',
  initialState,
  reducers: {
    ...budgetAllocatorReducers,
    ...campaignDurationReducers,
    ...campaignCalendarReducers,
    ...mixMediaModelerReducers,
    ...rankingFileUploadTable,
    ...updateMediaSpecificDetailsRadioReducers
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlans.fulfilled, (state, action) => {
      const { planRequests } = action.payload;
      state.planRequests = planRequests;
      state.isLoading = false;
    });
    builder.addCase(fetchPlans.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPlans.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPlanRequestsCount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPlanRequestsCount.fulfilled, (state, action) => {
      const { count } = action.payload;
      state.count = count;
      state.isLoading = false;
    });
    builder.addCase(getPlanRequestsCount.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addPlanAgent.fulfilled, (state, action) => {
      let planRequests = state.planRequests,
        args = action.meta.arg,
        idx = planRequests.findIndex((plan: any) => plan._id === args.planId);

      planRequests[idx].assignedTo = args.agent;
      state.planRequests = planRequests;
    });
    builder.addCase(uploadPlan.fulfilled, (state, action) => {
      const { payload } = action;
      let planRequests = state.planRequests,
        idx = planRequests.findIndex(
          (plan: any) => plan._id === payload.newPlan._request
        );
      if (!planRequests[idx].plans) planRequests[idx].plans = [];
      const newPlan = { ...payload.newPlan, _user: payload.user };
      planRequests[idx].plans.push(newPlan);

      if (payload.status) planRequests[idx].status = payload.status;
      if (payload.createdBy) planRequests[idx].createdBy = payload.createdBy;

      state.planRequests = planRequests;
    });
    builder.addCase(assignBulkPlanRequestAgent.fulfilled, (state, action) => {
      const { agent, requestIds } = action.payload;
      requestIds.forEach((id: string) => {
        let idx = state.planRequests.findIndex((item: any) => item._id === id);
        if (idx > -1) {
          state.planRequests[idx] = {
            ...state.planRequests[idx],
            assignedTo: agent
          };
        }
      });
    });
    builder.addCase(fetchPlan.pending, (state, action) => {
      state.planLevelIsLoading = true;
    });
    builder.addCase(fetchPlan.fulfilled, (state, action) => {
      if (action?.payload?.planRequest) {
        state.plan = action.payload.planRequest;
      }
      state.planLevelIsLoading = false;
    });
    builder.addCase(fetchPlan.rejected, (state, action) => {
      state.planLevelIsLoading = false;
    });
    builder.addCase(uploadRankingFile.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan.hash = action.payload.updatedPlan.hash;
      }
    });
    builder.addCase(campaignDurationAddMonth.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan.hash = action.payload.updatedPlan.hash;
      }
    });
    builder.addCase(submitBrief.fulfilled, (state, action) => {
      if (action.payload) {
        state.plan._campaign.brief = action.payload;
      }
    });
    builder.addCase(updateCurrentState.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan.hash = action.payload.updatedPlan.hash;
      }
    });
    builder.addCase(updateWeightage.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan.hash = action.payload.updatedPlan.hash;
      }
    });
    builder.addCase(updateRankingFileUpload.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan.hash = action.payload.updatedPlan.hash;
      }
    });
    builder.addCase(getTgBankData.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan.hash = action.payload.updatedPlan.hash;
      }
    });
    builder.addCase(calculateMediaMixModeler.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan.hash = action.payload.updatedPlan.hash;
      }
    });
    builder.addCase(updateMediaMixModeler.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan.hash = action.payload.updatedPlan.hash;
      }
    });
    builder.addCase(updateCampaignDuration.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan.hash = action.payload.updatedPlan.hash;
      }
    });
    builder.addCase(updateCampaignCalendar.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan.hash = action.payload.updatedPlan.hash;
      }
    });
    builder.addCase(updateBudgetAllocator.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan.hash = action.payload.updatedPlan.hash;
      }
    });
    builder.addCase(briefFeedback.pending, (state) => {
      state.isSubmittingBriefFeedback = true;
    });
    builder.addCase(briefFeedback.fulfilled, (state, action) => {
      const { planId, feedback } = action.payload;
      let idx = state.planRequests.findIndex(
        (plan: any) => plan._id === planId
      );
      if (idx > -1)
        state.planRequests[idx] = {
          ...state.planRequests[idx],
          briefFeedback: feedback
        };
      state.isSubmittingBriefFeedback = false;
    });
    builder.addCase(briefFeedback.rejected, (state) => {
      state.isSubmittingBriefFeedback = false;
    });
    builder.addCase(mediaPlanFeedback.pending, (state) => {
      state.isSubmittingPlanFeedback = true;
    });
    builder.addCase(mediaPlanFeedback.fulfilled, (state, action) => {
      const { requestId, planId, feedback } = action.payload;
      let idx = state.planRequests.findIndex(
        (plan: any) => plan._id === requestId
      );
      if (idx > -1) {
        let planIdx = state.planRequests[idx].plans.findIndex(
          (item: any) => item._id === planId
        );
        if (planIdx > -1)
          state.planRequests[idx].plans[planIdx] = {
            ...state.planRequests[idx].plans[planIdx],
            feedback
          };
      }
      state.isSubmittingPlanFeedback = false;
    });
    builder.addCase(mediaPlanFeedback.rejected, (state) => {
      state.isSubmittingPlanFeedback = false;
    });
    builder.addCase(getMediaOptionTemplateId.fulfilled, (state, action) => {
      const { mediaOptionTemplate, languages } = action.payload;
      if (mediaOptionTemplate) {
        state.mediaOptionsTemplateIds = mediaOptionTemplate;
      }
      if (languages) {
        state.languages = languages.map(
          (language: { _id: string }) => language._id
        );
      }
    });
    builder
      .addCase(competitorFileUpload.fulfilled, (state, action) => {
        if (action?.payload?.updatedPlan) {
          state.plan = {
            ...action.payload.updatedPlan,
            _campaign: { ...state.plan._campaign }
          };
        }
      })
      .addCase(addToMediaSpaceHash.fulfilled, (state, action) => {
        if (action?.payload?.updatedPlan) {
          state.plan = {
            ...action.payload.updatedPlan,
            _campaign: { ...state.plan._campaign }
          };
        }
      })
      .addCase(updateState.fulfilled, (state, action) => {
        if (action?.payload?.updatedPlan && action?.payload?.hashName) {
          console.log(action.payload.updatedPlan, 'hello plan');
          state.plan.currentState = action.payload.updatedPlan.currentState;
          state.plan[action.payload.hashName as keyof IPlan] =
            action.payload.updatedPlan[action.payload.hashName];
        }
      });
    updateMediaSpecificDetailsRadioExtraReducers(builder);
    radioHashRankingFileUploadExtraReducers(builder);
    radioHashMediaSpaceSelectedStatonsExtraReducers(builder);
    radioHashCampaignDurationExtraReducers(builder);
    radioHashCampaignCalendarExtraReducers(builder);
    radioHashBudgetAllocatorExtraReducers(builder);

    updateMediaSpecificDetailsPrintExtraReducers(builder);
    printHashRankingFileUploadExtraReducers(builder);
    printHashMediaSpaceSelectedStatonsExtraReducers(builder);
    printHashCampaignDurationExtraReducers(builder);
    printHashCampaignCalendarExtraReducers(builder);

    outdoorHashVendorCollectionExtraReducers(builder);
    outdoorHashVendorExcelUploadExtraReducers(builder);
    outdoorHashListingAndTaggingExtraReducers(builder);
    outdoorHashMappingExtraReducers(builder);
    outdoorHashBudgetAllocatorExtraReducers(builder);
    outdoorHashCampaignCalendarExtraReducers(builder);
    digitalHashExtraReducers(builder);
  }
});

export const planRequestsActions = planRequests.actions;

export default planRequests.reducer;
