import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Stack,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button
} from '@mui/material';
import logo from './tma-logo.png';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { Bug, ChevronDown, Laptop, Logout } from 'mdi-material-ui';
import { useNavigate, useLocation } from 'react-router-dom';

declare global {
  interface Window { LOQ: any; }
}

window.LOQ = window.LOQ || [];

export default function Header() {
  const user = useSelector((state: RootState) => state.session?.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOut = () => {
    navigate(`/login?redirect=${location?.pathname}`);
    localStorage.removeItem('authToken');
  };

	useEffect(() => {
		if(user){
			window.LOQ.push(['ready', async (LO: any) => {					
					// Or, identify a visitor
					await LO.$internal.ready('visitor')
					LO.visitor.identify(user.email, { email: user.email })
			}])
		}
	}, [user])
  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: 1200, background: '#fff' }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src={logo} style={{ width: '60px' }} alt="the media ant" />
          </Typography>
          <Button
            component="a"
            target='_blank'
            sx={{ fontWeight: 500}}
            startIcon={<Laptop />}
            href="https://themediaant.on.spiceworks.com/portal/registrations"
          >
            IT Support
          </Button>
          <Button
            onClick={() =>
              window.open(
                'https://forms.clickup.com/9002008441/f/8c8zfvt-2796/H0M588VMH1VPXTPD7T'
              )
            }
            startIcon={<Bug sx={{ marginRight: '-8px !important' }} />}
            sx={{ fontWeight: 500, margin: '0px 20px' }}
          >
            Bug Tracker
          </Button>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ cursor: 'pointer', pr: '24px' }}
            onClick={handleClick}
          >
            <Avatar
              src={user?._ppic}
              alt={user?.name}
              sx={{ width: 40, height: 40 }}
            />
            <Box>
              <Typography variant="h4">{user?.name}</Typography>
              <Typography variant="caption">{user?.role}</Typography>
            </Box>
            <ChevronDown sx={{ width: 24, height: 24, color: '#4E3BC9' }} />
          </Stack>
        </Toolbar>
      </AppBar>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PaperProps={{
          style: {
            padding: '10px 0px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
          }
        }}
      >
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}